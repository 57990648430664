import {IconButton, Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, {useRef} from "react";
import {toJpeg} from 'html-to-image'
import {jsPDF} from 'jspdf';
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import Tooltip from "@material-ui/core/Tooltip";

export const CardChart = ({title, subtitle = '', children, rightContent, showExport = true, printRef, exportFn = undefined}) => {
    const handleExport = () => !!exportFn ? exportFn() : defaultExportFn();

    const defaultExportFn = async () => {
        const element = printRef.current;
        console.log("element", element);
        // const canvas = await html2canvas(element);
        // const data = canvas.toDataURL('image/png');
        const data = await toJpeg(element)

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };

    return (
        <Paper ref={printRef}>
            <Box className={'d-flex align-items-center justify-content-between'}>
                <Box className={'pt-3 pb-3 pl-4'}>
                    <Typography variant={"body1"} color={"textSecondary"}>{title}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>{subtitle}</Typography>
                </Box>
                <Box className={'pr-4'} alignItems={"center"} display={"flex"}>
                    {rightContent}
                    {/*{showExport && (*/}
                    {/*    <Tooltip title={"Exportar"}>*/}
                    {/*        <IconButton color="inherit" onClick={handleExport}>*/}
                    {/*            <DownloadIcon style={{color: "darkslategray"}}/>*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*)}*/}
                </Box>
            </Box>
            {children}
        </Paper>
    )
}
